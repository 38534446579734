.generate-container {
  padding: 25px;
}
.generate-date {
  max-width: 230px;
  margin: 0 auto 15px;

  .generate-date_label {
    text-align: center;
    padding: 4px 0;
  }
}
.generate-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  padding: 2px;
  border-radius: 1.1em;
  max-width: 400px;
  margin: 0 auto;

  .generate-selector_item {
    flex: 1 0 auto;
    line-height: 2;
    font-weight: bold;
    text-align: center;
    border-radius: 1em;
    color: #999;
    transition: all 0.2s;
    cursor: pointer;

    &.current {
      background-color: $primary;
      color: #fff;
      cursor: default;
    }
    &:not(.current):hover {
      color: #111;
    }
  }
}
.generate-table-container {
  padding-bottom: 25px;
}

// .generated-projects {
//   position: relative;

//   &.loading {
//     min-height: 200px;
//   }
//   .generated-projects_list {
//     padding-bottom: 25px;
//   }
// }
