.paginator{
  .paginator-row{
    padding: 10px 0;
    text-align: center;

    .paginator-item{
      width: 26px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
      height: 26px;
      margin: 0 2px;
      display: inline-block;
      cursor: pointer;
      color:#444;
      transition: color .1s;

      &:not(.paginator-item_points):not(.paginator-item_current):hover{
        color:#AAA;
      }

      &.paginator-item_current{
        color:$primary;
        text-decoration: underline;
        cursor: default;
      }
      &.paginator-item_points{
        cursor: default;
      }
      &.paginator-item_first,
      &.paginator-item_last{
        font-size: 12px;
      }

    }
  }
}