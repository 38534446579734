// body {
//   font-size: 12px;
// }

a {
  color: $primary;
}
.muted {
  opacity: 0.6;
}
.italic {
  font-style: italic;
}
