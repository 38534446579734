.form-container {
  position: relative;
  .form-react {
    transition: opacity 0.15s;
  }

  &.loading {
    .form-react {
      opacity: 0.3;
    }
  }
}

.input-container {
  margin-bottom: 1.4em;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0.4em;

  .req {
    display: inline-block;
    padding: 0 0.2em;
    color: $primary;
  }
}

.form-control,
.form-select,
input,
textarea,
select {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}

textarea.form-control {
  min-height: 120px;
}

select,
.form-check-label {
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-style: italic;
  opacity: 0.6 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  opacity: 0.6 !important;
}
::placeholder {
  /* Firefox 18- */
  font-style: italic;
  opacity: 0.6 !important;
}

.input-group {
  &.with-before {
    input,
    select {
      border-left: none;
    }
  }
  &.with-after {
    input,
    select {
      border-right: none;
    }
  }

  .input-group-text {
    &.after {
      background-color: #fff;
    }
  }

  &.is-invalid {
    .input-group-text {
      border-color: $danger;
      color: $danger;
    }
  }
}

.form-question {
  $w: 18px;

  display: inline-block;
  width: $w;
  height: $w;
  line-height: $w;
  text-align: center;
  background-color: $secondary;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
}
.input-group {
  .input-loading {
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    font-size: 18px;
    opacity: 0.6;
  }
}
.input-drop-pad_cont {
  position: absolute;
  z-index: 1200;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0 10px;

  .input-drop-pad {
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-height: 480px;
    overflow-y: auto;
  }
}

.form-info-control {
  padding: 6px 0;
}

.no-write {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;

  input {
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
  }
}
// checkbox-click
.checkbox-click {
  $w: 18px;
  display: inline-block;
  width: $w;
  height: $w;
  border-radius: 20%;
  border: 1px solid #999;
  cursor: pointer;
  text-align: center;
  line-height: $w - 2px;
  color: #fff;

  .icon {
    opacity: 0;
  }

  &.checked,
  &:hover {
    background-color: $primary;
    border-color: $primary;
    .icon {
      opacity: 1;
    }
  }
  &:not(.checked):hover {
    opacity: 0.4;
  }
}
