.avatar {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 1px #ccc;
  position: relative;
  background-color: $red-light-color;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .avatar-letter {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    text-align: center;
    font-weight: bold;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
  }

  &.sm {
    width: 28px;
    height: 28px;
  }
}
