.main-logo {
  height: $main-header-height;
  padding: 5px 0;

  img {
    display: block;
    width: auto;
    height: 100%;
  }
}
