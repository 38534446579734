// COLORS

$primary: #088c9b;
$secondary: #008577;
$text-color: #333;
$red-color: #ff4f4f;
$red-light-color: #d17878;

$bg-body-1: #088c9b;
$bg-body-2: rgb(34, 193, 195);

$danger: $red-color;

$body-bg: #eee;
$header-bg: $secondary;
$text-menu-color: #444;

// SIZES
$main-header-height: 60px;
$main-footer-height: 40px;

// mobile limit
$mobile_limit: 992px;
