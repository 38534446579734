.loading-graph{
  color:$primary;
}

.loading-box{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .loading-box-container{
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 25vh;

    .loading-box-graph{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}