html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  background-color: $body-bg;
  overflow-y: scroll;
}

#root {
  height: 100%;
}

.main-container {
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto;
}

.main-container-wide {
  margin: 0 40px;
}

.pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: $primary;
  outline: none;
}
