.main-breadcrumbs{
  margin-bottom: 15px;
  font-size: 13px;
  .main-breadcrumbs-item{
    color:darken($primary,20);
   display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    line-height: 20px;

    &:hover{
      text-decoration: underline;
    }
  }
  .main-breadcrumbs-chevron{
    display: inline-block;
    vertical-align: middle;
    line-height: 15px;
    padding: 0 .3em;
    opacity: 0.3;
  }
}