.main-footer {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  height: $main-footer-height;
  line-height: $main-footer-height;

  .footer-container {
    box-shadow: 0 -1px 0 0 #ccc;
    text-align: center;
    font-size: 12px;
  }
}
