.btn {
  &.btn-primary {
    background-color: $primary;
    border: none;
    transition: opacity 0.1s;
    &:hover {
      opacity: 0.8;
    }
  }
  &.btn-cancel {
    color: #777;
    &:hover {
      background-color: #eee;
    }
  }
}
