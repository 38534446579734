.sign {
  position: relative;
  .sign-header {
    background-color: $header-bg;
    padding: 10px 0;
    .main-logo {
      // height: 120px;
      img {
        margin: 0 auto;
      }
    }
  }
  .sign-body {
    padding: 25px;
  }

  .loading-box {
    background-color: rgba(255, 255, 255, 0.7);
    .loading-box-container {
      max-height: 100%;
    }
  }
}
