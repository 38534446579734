$table-ui-border: 1px solid #ddd;

.table-ui {
  position: relative;
  font-size: 14px;
  border-top: $table-ui-border;
  border-left: $table-ui-border;
  border-right: $table-ui-border;

  .table-ui_row {
    border-bottom: $table-ui-border;
    .table-ui_col {
      padding: 0.5em 1em;
      &.for-sort {
        padding-left: 1.4em;
      }
      &.sorteable {
        cursor: pointer;
        transition: background-color 0.105s;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .table-ui_no-items {
    border-bottom: $table-ui-border;
    text-align: center;
    padding: 1em;
    font-style: italic;
  }
  .table-ui_error {
    border-bottom: $table-ui-border;
    text-align: center;
    padding: 1em;
    font-style: italic;
    color: #d00;
  }

  .table-ui_sort {
    width: 17px;
    height: 14px;
    padding-right: 5px;

    .table-ui_sort-ic {
      height: 7px;
      text-align: center;
      position: relative;
      line-height: 0;
      opacity: 0.3;

      &:after {
        position: absolute;
        left: 0px;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px;
      }
      &.ic-asc {
        &:after {
          top: 0;
          border-top-width: 0;
          border-color: transparent transparent #000;
        }
      }
      &.ic-desc {
        &:after {
          bottom: 0;
          border-bottom-width: 0;
          border-color: #000 transparent transparent;
        }
      }
    }

    &.sort-asc {
      .table-ui_sort-ic.ic-asc {
        opacity: 1;
      }
    }
    &.sort-desc {
      .table-ui_sort-ic.ic-desc {
        opacity: 1;
      }
    }
  }

  .table-ui_header {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .table-ui_body {
    .table-ui_row {
      transition: background-color 0.1s;
      &:hover {
        background-color: rgba($primary, 0.1);
      }
    }
  }
}
.table-ui_action-toggle {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.dropdown.show {
  .table-ui_action-toggle {
    color: $primary;
  }
}

.table-ui_wrap {
  position: relative;

  .table-ui_content {
    transition: opacity 0.15s;
  }

  &.loading {
    .table-ui_content {
      opacity: 0.3;
    }
  }
}

.table-logo {
  max-width: 70px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 991px) {
  .table-ui-container {
    width: 100%;
    overflow-x: scroll;
  }
  .table-ui {
    min-width: 950px;
  }
}
