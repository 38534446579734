$filters-container-w: 260px;

.filters-container {
  width: $filters-container-w;
  position: relative;
}
.filter-mobile-btn {
  display: none;
}
.filters-container-float {
  position: relative;
  top: -20px;
  width: $filters-container-w;
  background-color: $body-bg;

  // overflow-y: auto;
  // overflow-x: hidden;
  padding-top: 20px;
  padding-right: 5px;
  padding-bottom: 15px;

  transition: padding-top 0.1s;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &.fixed {
    position: fixed;
    z-index: 15;
    top: $main-header-height;
    padding-bottom: 1.5 * $main-header-height;
    height: 100vh;
  }
}

@media screen and (max-width: 1200px) {
  .filters-container {
    width: 200px;
  }
  .filters-container-float {
    width: 200px;
  }
}
@media screen and (max-width: 992px) {
  .filter-mobile-btn {
    display: inline-block;
  }
  .filters-container {
    width: 100%;
    position: relative;
    top: -20px;
    border-bottom: 1px dotted #ccc;
  }
  .filters-container-float {
    display: none;
    top: 5px;
    width: 100%;

    &.fixed {
      position: relative;
      top: 20px;
      height: auto;
    }
    &.mobileVisible {
      display: block;
    }
  }
}
