.modal-content {
  border: none;
  //border-radius: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.3);
}
.modal-backdrop {
  background-color: rgb(187, 187, 187);
}

.modal-dialog.mb-full {
  max-width: 1100px;
  padding-left: 5px;
  padding-right: 5px;
}
