.main-section {
  display: block;
  position: relative;
  padding: 30px 0;
}

.main-section-header {
  display: block;
  margin-bottom: 35px;
  .main-section-header-top-row {
    margin-bottom: 15px;
  }
}
