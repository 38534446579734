.main-admin {
  height: 100%;
  position: relative;
  padding-top: $main-header-height;

  .main-admin-container {
    min-height: 100%;
    position: relative;
    padding-bottom: $main-footer-height;
  }
}
